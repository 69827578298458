<template>
  <div id="data-list-list-view" class="data-list-container" :class="initiator">
    <vs-button
      @click="getClinics"
      :disabled="
        $store.state.AppActiveUser.userType !== 'clinic' &&
        $store.state.AppActiveUser.userType !== 'subClinic' &&
        clinicCount === 0
      "
      class="lg:hidden h-full"
      color="primary"
      >Invite {{ invitationType }}</vs-button
    >
    <vs-button
      @click="getClinics"
      :disabled="
        $store.state.AppActiveUser.userType !== 'clinic' &&
        $store.state.AppActiveUser.userType !== 'subClinic' &&
        clinicCount === 0
      "
      class="hidden lg:inline"
      color="primary"
      >Invite {{ invitationType }}</vs-button
    >
    <vs-popup title="Send Invitation" :active.sync="popupActivo">
      <vs-input
        :success="!errors.first('invitation.email') && email != ''"
        val-icon-success="done"
        val-icon-danger="error"
        :danger="errors.first('invitation.email') ? true : false"
        v-validate="'required|email'"
        name="email"
        data-vv-as="email"
        label="Email"
        class="w-full m-2"
        data-vv-scope="invitation"
        :danger-text="errors.first('invitation.email')"
        v-model="email"
      />
      <div class="w-full m-5">
        <label style="display: block" class="vs-input--label custom-label mb-1"
          >Is nurse a contractor?</label
        >
        <vs-radio
          vs-value="Yes"
          vs-name="radios3"
          style="margin-right: 20px"
          v-model="isContractor"
          >Yes</vs-radio
        >
        <vs-radio vs-value="No" vs-name="radios3" v-model="isContractor"
          >No</vs-radio
        >
      </div>
      <vs-select
        v-if="invitedBy == 'franchise' || invitedBy == 'subFranchise'"
        placeholder="Select Clinic"
        label="Clinic"
        autocomplete
        v-model="clinic"
        class="w-full m-2"
      >
        <vs-select-item
          :key="index"
          :value="item._id"
          :text="item.clinicName ? item.clinicName : item.firstName"
          v-for="(item, index) in clinics"
        />
      </vs-select>

      <vs-button
        @click="sendInvitation"
        color="primary"
        class="m-2"
        type="border"
        >Send</vs-button
      >
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "invite-user",
  props: {
    initiator: {
      type: String,
      default: "Dashboard",
    },
    invitationType: {
      type: String,
      default: "Doctor",
    },
    invitedBy: {
      type: String,
      default: "franchise",
    },
    clinicCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isContractor: "No",
      email: "",
      popupActivo: false,
      clinics: [],
      clinic: "",
    };
  },
  methods: {
    ...mapActions("franchise", ["inviteUser", "fetchClinics"]),
    async sendInvitation() {
      let self = this;
      let isValid = await this.$validator.validateAll("invitation");

      if (this.$store.state.AppActiveUser.userType === "subClinic") {
        this.clinic = this.$store.state.AppActiveUser.parentClinicId;
      }

      this.inviteUser({
        email: this.email,
        clinicId: this.clinic,
        type: this.invitationType,
        isContractor: this.isContractor == "Yes" ? true : false,
      })
        .then((res) => {
          self.email = "";
          self.clinic = "";
          self.isContractor = "No";
          self.$validator.reset();
          self.$vs.notify({
            title: "Invitation Sent",
            text: "Invitation Sent Successfully.",
            color: "success",
          });
          this.popupActivo = false;
        })
        .catch((err) => {
          self.email = "";
          self.clinic = "";
          self.$validator.reset();
          self.$vs.notify({
            title: err.data.title,
            text: err.data.message,
            color: "danger",
          });
          this.popupActivo = false;
        });
    },
    getClinics() {
      let self = this;
      this.popupActivo = true;
      this.fetchClinics({
        type: this.invitedBy,
      }).then((res) => {
        if (self.invitedBy === "franchise") {
          this.clinics = res.data.data;
        } else {
          this.clinic = res.data.data.clinicId;
        }
      });
    },
  },
};
</script>

<style scoped>
div.management {
  margin: 0 !important;
}
</style>
