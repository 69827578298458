<template>
  <div>
    <vs-col vs-w="12">
      <vs-row vs-type="flex" vs-w="3" vs-sm="3" vs-xs="12">
        <h1 class="primary font-bold" style="color: #555555">Resources</h1>
      </vs-row>

      <div class="mt-6 flex flex-col lg:hidden">
        <div
          class="mt-5 w-full"
          v-for="category in getAllCategories()"
          :key="category"
        >
          <div>
            <h4 class="mb-2">{{ category }}</h4>
            <div
              v-for="(item, index) in getItems(category)"
              :key="index"
              class="
                bg-white
                py-3
                px-2
                mt-2
                hover:shadow-lg
                shadow-sm
                cursor-pointer
                rounded-lg
              "
            >
              <div @click="goTo(item)" class="flex items-center">
                <img :src="getImage(item)" class="h-12 w-12 mr-4" />
                <p class="font-bold text-base">
                  {{ item.title[0].text }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-6 hidden lg:flex">
        <vs-row vs-w="12" class="mt-2">
          <vs-col vs-w="4">
            <vs-row
              v-for="(item, index) in getCategories()"
              :key="item"
              vs-type="flex"
              vs-w="10"
              class="mt-3"
            > 
              <vs-button
                :type="isActive(item)"
                color="primary"
                class="w-full h-10"
                @click="setActive(item, index)"
                >{{ item }}</vs-button
              >
            </vs-row>
          </vs-col>

          <vs-col vs-w="4">
            <h4 class="mb-5">{{ category }}</h4>
            <div
              v-for="(item,index) in getSelectedResources()"
              :key="index"
              class="
                bg-white
                py-3
                px-2
                mt-5
                hover:shadow-lg
                shadow-sm
                cursor-pointer
                rounded-lg
              "
            >
              <div :key="index" @click="goTo(item)" class="flex items-center">
                <img :src="getImage(item)" class="h-12 w-12 mr-4" />
                <p class="font-bold text-base">
                  {{ item.title[0].text }}
                </p>
              </div>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </vs-col>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import inviteUser from "../components/others/inviteUser";
import moment from "moment";

export default {
  components: {
    inviteUser,
  },
  data() {
    return {
      resources: [],
      activeCategory: "",
      category: "",
    };
  },
  methods: {
    ...mapActions("basic", ["fetchAllResources"]),
    moment(date) {
      return moment(date).format("DD MMM YYYY HH:mm a");
    },
    getCategories() {
      return Object.keys(this.resources).map((time) => time);
    },
    getAllCategories() {
      return Object.keys(this.resources);
    },
    getItems(key) {
      return this.resources[key];
    },
    getImage(item) {
      const type = item.resource_type.slug;
      if (type === "vimeoyoutube") return "/video-icon.png";
      else return "/pdf-icon.png";
    },
    getData() {
      this.$vs.loading();
      this.fetchAllResources()
        .then((res) => {
          const data = res.data.data;
          const items = {};
          data.filter((item) => item.data.category.data).map((item) => {
            const key = item.data.category.data.category[0].text;
            if (items[key] !== undefined) {
              items[key] = [...items[key], { ...item.data }];
            } else {
              items[key] = [{ ...item.data }];
            }
          });

          this.resources = items;
          if (items !== {}) {
            this.activeCategory = Object.keys(this.resources).map(
              (time) => time
            )[0];
            this.category = Object.keys(this.resources).map((time) => time)[0];
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
        });
    },
    getSelectedResources() {
      return this.resources[this.category];
    },
    setActive(item, index) {
      this.activeCategory = index;
      this.category = item;
    },
    isActive(item) {
      if (this.activeCategory !== item) return "border";
      else return "filled";
    },
    goTo(item) {
      const type = item.resource_type.slug;
      const iframeView = localStorage.getItem("iframe-view");
      let link;
      let target = "_blank"

      if (iframeView) target = "_self"

      if (type === "vimeoyoutube") link = item.vimeo_youtube_link[0].text;
      else link = item.file_link.url;
      window.open(link, target);
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped>
.heading {
  font-size: large;
  font-weight: 600;
  color: #000;
}
.count {
  font-size: x-large;
  font-weight: 700;
  text-align: center;
}
</style>
